import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

// Styles
import "../styles/vars.css"
import "../styles/main.css"
import "../styles/buttons.css"
// import '../styles/typography.css'

const Faq = ({ data, location }) => {
  const siteTitle = "Frequently asked questions"
  const siteDesc =
    "Frequently asked questions about themes, support, licensing and billing"

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={siteTitle} description={siteDesc} titleTemplate={false} />
      <h2>Topics</h2>
      <ul>
        <li>
          <a href="#technical">Technical questions</a>
        </li>
        <li>
          <a href="#support">Support</a>
        </li>
        <li>
          <a href="#licensing">Licensing</a>
        </li>
        <li>
          <a href="#billing">Billing</a>
        </li>
      </ul>

      <h2 id="technical">Technical questions</h2>

      <h3>How can I install a theme?</h3>

      <p>
        When you buy the theme you will also get the installation instructions,
        together with the theme documentation.
      </p>

      <h3 id="theme-types">What are the theme types?</h3>
      <p>
        A theme can provide one or more types to customize different aspects of
        Keycloak. The different types are:
      </p>
      <ul>
        <li>Account management</li>
        <li>Admin console</li>
        <li>Emails</li>
        <li>Login forms</li>
        <li>Welcome page</li>
      </ul>
      <p>
        In short, when you buy a theme that includes only the Login type, it
        will style only the login forms of your Keycloak installation.
      </p>

      <h3>Can I customize a theme?</h3>
      <p>
        The theme documentation describes some basic customizations, like
        changing the theme logo and the primary color.
      </p>
      <p>
        Our support covers for those customizations, but you are free to make
        more changes if you want.
      </p>

      <h2 id="support">Support</h2>

      <h3 id="basic-support">Do you provide support?</h3>
      <p>
        When you buy the theme you also get 6 months of basic support included.{" "}
        <br />
        Basic support helps you in the following cases:
      </p>
      <ul>
        <li>
          Assistance with bugs related to the current version of the theme.
        </li>
        <li>Availability to answer questions about the theme.</li>
      </ul>
      <p>
        Basic support doesn't cover for installation issues, further
        customization of the theme or upgrades to new Keycloak versions.
      </p>

      <h3>I have a support request, how can I contact you?</h3>
      <p>
        All support requests are managed by email. Please send an email to the
        address you find in your theme README file and we will help as soon as
        possible.
      </p>
      <p>
        In case you found a bug we will release a new version of the theme and
        all theme owners will be notified.
      </p>

      <h3>Do you support for theme customizations?</h3>
      <p>
        Basic support covers only for the customization documented in the README
        file of the theme you purchased.
      </p>

      <h3>Can you customize a theme for me?</h3>
      <p>
        Yes, we are available for further theme customizations. Those changes
        will be estimated and an hourly rate will be applied.
      </p>

      <h2 id="licensing">Licensing</h2>

      <h3>Can I remove the KeycloakThemes copyright in the theme footer?</h3>
      <p>Yes, as soon as you purchase a theme you can remove our copyright.</p>

      <h3>How many times can I use a theme?</h3>
      <p>A theme can be used only for one active installation of Keycloak.</p>

      <h2 id="billing">Billing</h2>

      <h3>Do you provide a money back guarantee?</h3>
      <p>Yes, we provide a 14 days money back guarantee on all our themes.</p>
    </Layout>
  )
}

export default Faq

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
